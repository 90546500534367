<template>
  <div class="CirclePercent">
    <div class="percent" :class="percentClass">{{ percent }}%</div>
    <div class="progress" :style="progressStyle"></div>
  </div>
</template>

<script>
export default {
  name: "CirclePercent",
  props: {
    percent: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressStyle() {
      return { top: 100 - this.percent + "%" };
    },
    percentClass() {
      return { "dark-color": this.percent >= 100 };
    },
  },
};
</script>

<style lang="scss" scoped>
.CirclePercent {
  width: 60px;
  height: 60px;
  background-color: $color-primary;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  .progress {
    background-color: $color-active;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 100%;
  }

  .percent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $color-white;
    font-size: 16px;
    font-weight: normal;
    z-index: 2;

    &.dark-color {
      color: $color-text-4;
    }
  }
}
</style>
