<template>
  <div class="RateOfAdvance" v-if="isShow">
    <!-- <div class="row" v-for="(obj, idx) of data" :key="idx">
      <AdvanceItem :isTest="obj.isTest" />
      <AdvanceItem
        :obj="item"
        v-for="(item, index) of obj.data"
        :key="index"
        :isTest="item.isTest"
      />
    </div> -->
    <div class="row">
      <AdvanceItem :isTest="isTest" />
      <AdvanceItem
        :obj="item"
        v-for="(item, index) of dataList"
        :key="index"
        :isTest="item.isTest"
      />
    </div>
  </div>
</template>

<script>
import AdvanceItem from "./AdvanceItem.vue";
import chunk from "lodash/chunk";
export default {
  name: "RateOfAdvance",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    AdvanceItem,
  },
  computed: {
    isShow() {
      return !!this.list.length;
    },

    dataList() {
      return this.list.map((item) => {
        if (item.desc) {
          item.isTest = true;
        } else {
          item.isTest = false;
        }
        return item;
      });
    },

    isTest() {
      return this.dataList.some((item) => item.isTest);
    },

    data() {
      const list = chunk(this.dataList, 6);
      const result = list.map((item) => {
        const res = { data: item };
        res.isTest = item.some((obj) => obj.isTest);
        return res;
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.RateOfAdvance {
  .row {
    display: inline-flex;
    flex-wrap: wrap;
    ::v-deep {
      .AdvanceItem:not(:last-of-type) {
        border-right: none;
      }

      .AdvanceItem:nth-child(odd) {
        background-color: $color-background-0;
      }
      .AdvanceItem:nth-child(7) {
        border-right: 1px solid $color-border-3;
      }
    }
  }
}
</style>
