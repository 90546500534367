<template>
  <div class="NoticeContent">
    <div class="arts">
      <article
        v-for="(item, index) of dataList"
        :key="index"
        class="article"
        :class="{
          active: activeIndex === index,
        }"
      >
        <header @click="handleItemClick(index)">
          <h3>{{ item.subject }}</h3>
          <p>{{ item.created }}</p>
        </header>
        <div class="content" v-html="item.content">
          <!-- <p>
            我们想花点时间提醒大家，从我们介绍 HBS
            在线模块中，有关剽窃的重要信息。剽窃是拿别人的话或想法，并把它们作为你自己的。在
            HBS
            在线，我们用你自己的话说对你自己的想法感兴趣，即使你的写作和想法并不完美。
          </p>
          <p>
            在你的课程中，有时你可能不知道问题的答案。当这种情况发生时，我们希望您尽力自己回答问题。想想你在课程中学到的东西，考虑你自己的工作和生活经验，如果绝对必要的话做少量的研究，然后做出最好的猜测。不要为了提交正确的答案而从外部来源（如网站）剪切和粘贴文本。
          </p>
          <p>
            课程中有一些地方（如可选的连接作业和少量的反射），我们要求您查找照片、示例、一组事实或一篇文章来回答问题。在这些情况下，可能需要进行研究，并将这些信息纳入您的响应中。但是，每当您使用外部信息时，您需要做三件重要的事情：
          </p>
          <p>
            在你的课程中，有时你可能不知道问题的答案。当这种情况发生时，我们希望您尽力自己回答问题。想想你在课程中学到的东西，考虑你自己的工作和生活经验，如果绝对必要的话做少量的研究，然后做出最好的猜测。不要为了提交正确的答案而从外部来源（如网站）剪切和粘贴文本。
          </p>
          <p>
            课程中有一些地方（如可选的连接作业和少量的反射），我们要求您查找照片、示例、一组事实或一篇文章来回答问题。在这些情况下，可能需要进行研究，并将这些信息纳入您的响应中。但是，每当您使用外部信息时，您需要做三件重要的事情：
          </p> -->
        </div>
      </article>
    </div>
    <div
      v-if="data != null && data.page + 1 >= data.totalPages"
      class="myStudy-more"
    >
      <div>没有更多了</div>
    </div>
    <div v-else class="myStudy-more1" @click="more">
      <div>更多</div>
    </div>
  </div>
</template>

<script>
import { getAnnouncement } from "@/api/announcementController";
export default {
  name: "NoticeContent",
  data() {
    return {
      activeIndex: 1,
      data: null,
      queryForm: {
        __page: 0,
        __pageSize: 10,
        published: true,
        __orderBy: "-created",
      },
      dataList: [],
    };
  },
  created() {
    this.getAnnouncement();
  },
  methods: {
    handleItemClick(index) {
      if (this.activeIndex === index) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
      }
    },
    getAnnouncement() {
      getAnnouncement(this.queryForm).then((res) => {
        if (res.code == 20000) {
          this.data = res;
          this.dataList = this.dataList.concat(res.data);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    more() {
      this.queryForm.__page++;
      this.getAnnouncement();
    },
  },
};
</script>

<style lang="scss" scoped>
.arts {
  height: 356px;
  overflow: auto;
  border: 1px solid $color-border-1;
}
.NoticeContent {
  padding: 18px;
  .article {
    border-bottom: 1px solid $color-border-1;
    // &:last-of-type {
    //   border-bottom: none;
    // }
    &.active {
      background: #f3f3f3;
      .content {
        display: block;
      }
      header {
        // margin-bottom: 16px;
      }
    }
  }
  header {
    cursor: pointer;
    padding: 16px 32px 18px;
    h3 {
      font-size: 16px;
      margin-bottom: 3px;
    }

    p {
      font-size: 12px;
    }
  }
  .content {
    display: none;
    font-size: 12px;
    line-height: 1.5;
    padding: 0px 32px 32px;
  }
}
.myStudy-more {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  div {
    display: inline-block;
    padding: 0 20px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    &::after {
      position: absolute;
      display: block;
      content: "";
      left: 0;
      top: 13px;
      height: 1px;
      width: 40px;
      background: #666666;
    }
    &::before {
      position: absolute;
      display: block;
      content: "";
      right: 0;
      top: 13px;
      height: 1px;
      width: 40px;
      background: #666666;
    }
  }
}
.myStudy-more1 {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  div {
    //display: inline-block;
    padding: 0 20px;
    //position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    &::after {
      position: absolute;
      display: block;
      content: "";
      left: 0;
      top: 13px;
      height: 1px;
      width: 40px;
      background: #666666;
    }
    &::before {
      position: absolute;
      display: block;
      content: "";
      right: 0;
      top: 13px;
      height: 1px;
      width: 40px;
      background: #666666;
    }
  }
}
</style>
