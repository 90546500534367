<template>
  <div class="EduHomeTabs">
    <div
      class="EduHomeTabItem"
      @click="handleClick(item.name)"
      :class="{ active: activeName === item.name }"
      v-for="(item, index) of tabs"
      :key="item.name"
    >
      <div class="icon" :class="`icon${index}`"></div>
      <span>{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EduHomeTabs",
  components: {},
  props: {
    activeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        { title: "全部科目", name: "addCourse" },
        { title: "我的账户", name: "myAccount" },
        // { title: "我的课程", name: "myCourse" },
        //{ title: "我的时间银行", name: "myTimeBank" },
        //{ title: "学习情况", name: "myStudy" },
        { title: "学习情况", name: "learning" },
        { title: "购买记录", name: "purchaseRecord" },
        { title: "公告", name: "notice" },
      ],
      // tabs: [
      //   { title: "公告", name: "NoticeContent" },
      //   { title: "我的进度", name: "ProgressContent" },
      //   { title: "我的时间银行", name: "CalendarContent" },
      // ],
    };
  },
  methods: {
    handleClick(name) {
      this.$emit("update:activeName", name);
    },
  },
};
</script>

<style lang="scss" scoped>
.EduHomeTabs {
  display: flex;

  ::v-deep .EduHomeTabItem {
    margin-right: 5px;
  }
}
.EduHomeTabItem {
  display: flex;
  align-items: center;
  width: 210px;
  height: 60px;
  border: 1px solid $color-border-0;
  border-radius: 5px 5px 0px 0px;
  border-bottom: none;
  padding: 0 16px;
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
  &.active {
    background-color: $color-primary;
    color: $color-white;
    .icon0 {
      background-image: url("~@/assets/home/NoticeWhite.png");
    }
    .icon1 {
      // background-image: url("~@/assets/home/ChartWhite.png");
      background-image: url("~@/assets/home/accountWhite.png"); // 账户
    }
    .icon2 {
      background-image: url("~@/assets/home/CalendarWhite.png");
    }
    .icon3 {
      background-image: url("~@/assets/home/NoticeWhite.png");
    }
    .icon4 {
      background-image: url("~@/assets/home/NoticeWhite.png");
    }
    .icon5 {
      background-image: url("~@/assets/home/NoticeWhite.png");
    }
  }
  .icon {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .icon0 {
    background-image: url("~@/assets/home/NoticeDark.png");
  }
  .icon1 {
    // background-image: url("~@/assets/home/ChartDark.png");
    background-image: url("~@/assets/home/accountDark.png");
  }
  .icon2 {
    background-image: url("~@/assets/home/CalendarDark.png");
  }
  .icon3 {
    background-image: url("~@/assets/home/NoticeDark.png");
  }
  .icon4 {
    background-image: url("~@/assets/home/ChartDark.png");
  }
  .icon5 {
    background-image: url("~@/assets/home/CalendarDark.png");
  }
}
</style>
