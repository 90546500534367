<template>
  <div class="myStudyBank">
    <div class="myStudy">
      <div class="myStudy-tabs">
        <ul>
          <li
            v-for="(courseItem, courseIndex) in courseData"
            :class="{ on: courseIndex == activeIndex }"
            @click="handleItemClick(courseIndex)"
            :key="courseIndex"
          >
            {{ courseItem.title }}
          </li>
          <!-- <li>审计</li>
          <li>财务管理</li>
          <li>经济法</li>
          <li>税法</li>
          <li>战略</li> -->
        </ul>
      </div>
      <div class="mt35">
        <div class="myStudy-title">查看学习情况</div>
        <!-- <div class="myStudy-ul">
          <ul>
            <li>● 累计学习时间</li>
            <li>
              ● 根据您的学习情况，推荐您学习以下课程<span class="aHred"
                >第一章第一节</span
              ><span class="aHred">第二章第三节</span
              ><span class="aHred">第四章第八节</span>
            </li>
          </ul>
        </div> -->
      </div>
      <div class="myStudy-table">
        <table border="0" width="100%" cellpadding="0" cellspacing="0">
          <tr>
            <th>课程</th>
            <th>是否完成</th>
            <th>课程完成情况</th>
          </tr>
          <tr
            v-for="(resourceItem, resourceIndex) in resourceData"
            :key="resourceIndex"
          >
            <td>
              <span v-if="resourceItem.refKind == 'PART'">
                {{ resourceItem.partTitle }}
              </span>
              <span v-else-if="resourceItem.refKind == 'CHAPTER'">
                {{ resourceItem.chapterTitle }}
              </span>
              <span v-else-if="chapterTitle.refKind == 'SECTION'">
                {{ resourceItem.sectionTitle }}
              </span>
              <!--第一章第一节-->
            </td>
            <td align="center">
              <img
                v-if="resourceItem.progress >= 100"
                src="~@/assets/myStudy/naviconitem-2.png"
              />
              <img v-else src="~@/assets/myStudy/naviconitem-3.png" />
            </td>
            <td align="center">
              <div v-if="resourceItem.refKind != 'PART'">
                学习进度: <span>{{ resourceItem.progress }}%</span>
              </div>
              <!-- <div>学习进度: <span>50%</span></div>
              <div>学习进度: <span>50%</span></div> -->
            </td>
          </tr>
          <!-- <tr>
            <td>第一章第一节</td>
            <td align="center">
              <img src="~@/assets/myStudy/naviconitem-3.png" />
            </td>
            <td align="center">
              <div>学习进度: <span>50%</span></div>
              <div>学习进度: <span>50%</span></div>
              <div>学习进度: <span>50%</span></div>
            </td>
          </tr>
          <tr>
            <td>第一章第一节</td>
            <td align="center">
              <img src="~@/assets/myStudy/naviconitem-2.png" />
            </td>
            <td align="center">
              <div>学习进度: <span>50%</span></div>
              <div>学习进度: <span>50%</span></div>
              <div>学习进度: <span>50%</span></div>
            </td>
          </tr>
          <tr>
            <td>第一章第一节</td>
            <td align="center">
              <img src="~@/assets/myStudy/naviconitem-2.png" />
            </td>
            <td align="center">
              <div>学习进度: <span>50%</span></div>
              <div>学习进度: <span>50%</span></div>
              <div>学习进度: <span>50%</span></div>
            </td>
          </tr> -->
        </table>
      </div>
      <div class="myStudy-more">
        <div>没有更多了</div>
      </div>
    </div>
  </div>
</template>

<script>
import EduButton from "@/components/EduButton";
import { getMemberResource } from "@/api/memberController.js";
import { getCourse } from "@/api/course.js";
export default {
  name: "myStudyBank",
  components: {
    EduButton,
  },
  data() {
    const list = Array(5).fill({
      title: "课时倒计时",
      time: "2021年8月21日下午01:20东部时间",
    });
    return {
      list,
      //memberResourceData: [],
      courseData: [],
      activeIndex: 0,
      resourceData: [],
    };
  },
  created() {
    this.getCourse();
    //this.getMemberResource();
  },
  methods: {
    handleItemClick(index) {
      this.activeIndex = index;
      this.resourceData = [];
      this.getMemberResource();
    },
    getCourse() {
      getCourse({ categoryId: parent.CATEGORY_ID }).then((res) => {
        if (res.code == 20000) {
          this.courseData = res.data;
          if (res.data.length > 0) {
            this.getMemberResource();
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getMemberResource() {
      getMemberResource({
        type: "All",
        courseId: this.courseData[this.activeIndex].id,
      }).then((res) => {
        if (res.code == 20000) {
          this.resourceData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.myStudy {
  padding: 50px 80px;
  .myStudy-title {
    font-weight: bold;
    line-height: 48px;
    font-size: 28px;
    font-weight: 400;
    color: #575B67;
  }
  .myStudy-ul {
    padding: 15px 0;
    font-size: 16px;
    li {
      clear: both;
      position: relative;
      color: #575b67;
      line-height: 40px;
      padding: 5px 0;
      &.lh64 {
        line-height: 64px;
        span {
          float: left;
          display: block;
        }
        div {
          display: block;
          height: 64px;
          background: #ffcf00;
          border-radius: 6px;
          border: 1px solid #707070;
          padding: 0 15px;
          float: left;
          font-size: 60px;
          margin-left: 15px;
          margin-right: 10px;
          line-height: 64px;
          font-weight: bold;
          color: #090909;
        }
      }
      i {
        color: #173d7c;
        font-style: normal;
        font-weight: bold;
      }
      .aHred {
        font-size: 16px;
        font-weight: bold;
        color: #173d7c;
        text-decoration: underline;
        margin-left: 15px;
        cursor: pointer;
      }
      // &::before{
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   display: block;
      // }
      &::after {
        clear: both;
        display: block;
        content: "";
      }
    }
  }
  .myStudy-more{
    text-align: center;
    margin-top: 15px;
    div{
      display: inline-block;
      padding: 0 50px;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      &::after{
        position: absolute;
        display: block;
        content: '';
        left: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
      &::before{
        position: absolute;
        display: block;
        content: '';
        right: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
    }
  }
  .myStudy-table{
    border-top: 3px solid #707070;
    border-bottom: 3px solid #707070;
    table{
      th{
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #575B67;
        padding: 8px 12px;
      }
      td{
        border-top: 1px solid #707070;
        font-size: 16px;
        font-weight: 400;
        color: #575B67;
        padding: 8px 12px;
        line-height: 32px;
        background: #FCF8E9;
        div{
          margin: 0 10px;
          display: inline-block;
          span{
            color: #173D7C;
          }
        }
      }
      tr:nth-child(2n){
        td{
          background: #fff;
        }
      }
    }
  }
}
.list {
  margin: 18px 18px 33px 18px;
  border: 1px solid $color-border-1;
  height: 225px;
  overflow: auto;
  .item {
    border-bottom: 1px solid $color-border-1;
    padding: 16px 32px 18px;
    background-color: $color-background-1;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 3px;
    }

    p {
      font-size: 12px;
    }
    &:hover {
      background-color: $color-background-2;
    }
  }
}

.timeout {
  border-top: 1px solid $color-border-1;
  padding-bottom: 45px;
  .desc {
    font-weight: bold;
    text-align: center;
    padding: 35px 0 54px;
  }
}

.btns {
  text-align: center;

  button {
    margin: 0 10px;
  }
}
.myStudy-tabs{
  ul{
    li{
      float: left;
      margin-left: 1px;
      min-width: 182px;
      height: 50px;
      background: #F1F1F1;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #CCCCCC;
      padding: 0 15px;
      box-sizing: content-box;
      text-align: center;
      line-height: 50px;
      position: relative;
      font-size: 16px;
      font-weight: 300;
      color: #707070;
      font-weight: bold;
      cursor: pointer;
      &.on,&:hover{
        width: 182px;
        height: 50px;
        background: #F3C912;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #F3C912;
        color: #333333;
        &::after{
          position: absolute;
          left: 50%;
          margin-left: -10px;
          border: 10px solid transparent;
          border-top: 10px solid #F3C912;
          bottom: -20px;
          content: '';
          display: block;
        }
      }
    }
  }
  &::after{
    clear: both;
    display: block;
    content: '';
  }
}
.mt35{
  margin-top: 35px;
}
</style>
