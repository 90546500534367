<template>
  <div class="HomeCardItem">
    <h3>{{ obj.name }}</h3>
    <div class="btns">
      <EduButton @click.native="goToSubject">教学大纲</EduButton>
      <EduButton @click.native="goToSubject" type="primary">继续学习</EduButton>
    </div>
    <div class="desc">
      <div class="item">
        <h4>我的最近购买课程</h4>
        <div class="link">{{ obj.recent }}</div>
      </div>
      <div class="item">
        <h4>最近热门购买课程</h4>
        <div class="link">{{ obj.hot }}</div>
      </div>
    </div>
    <el-image class="pic" :src="obj.pic" fit="cover"></el-image>
  </div>
</template>

<style lang="scss" scoped>
.HomeCardItem {
  width: 285px;
  border: 1px solid $color-border-0;
  font-size: 0;

  .pic {
    width: 100%;
    height: 192px;
  }

  .desc {
    background-color: $color-background-4;
    color: $color-white;
    padding: 25px 16px 0;

    .item {
      padding-bottom: 25px;
    }

    .link {
      color: $color-text-4;
      background-color: $color-white;
      border-radius: 5px;
      font-size: 14px;
      padding: 8px 24px;
      margin-top: 20px;
    }

    h4 {
      font-size: 14px;
      font-weight: 400;
    }
  }

  h3 {
    padding: 26px 16px 28px;
    font-size: 18px;
  }

  .btns {
    text-align: center;
    padding-bottom: 45px;
    button {
      margin: 0 8px;
      padding: 21px 28px;
      font-weight: bold;
    }
  }
}
</style>

<script>
import EduButton from "@/components/EduButton";
export default {
  name: "HomeCardItem",
  components: {
    EduButton,
  },
  props: {
    obj: {
      type: Object,
      required: true,
    },
  },
  created() {},
  methods: {
    goToSubject() {
      this.$router.push({
        path: "/Subject/6",
      });
    },
  },
};
</script>
