<template>
  <div class="tabs">
    <div
      class="tab-item"
      :class="getCls(item, index)"
      v-for="(item, index) of tabs"
      :key="index"
      @click="handleClick(item, index)"
    >
      {{ item.label }}
      <div class="lower-triangle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeIndex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getCls(item, index) {
      if (item.disabled) return "disabled";
      if (index === this.activeIndex) return "active";
      return "default";
    },
    handleClick(item, index) {
      if (item.disabled) return;
      if (index === this.activeIndex) return;
      this.$emit("update:activeIndex", index);
      this.$emit("click", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  margin-bottom: 6px;
  .tab-item {
    width: 277px;
    height: 50px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &.active {
      background-color: $color-active;
      .lower-triangle {
        display: block;
      }
    }

    &.default {
      background-color: $color-primary;
      color: $color-white;
    }

    &.disabled {
      border: 1px solid $color-border-2;
      color: #cfcdcd;
      background-color: #f8f8f8;
      cursor: not-allowed;
    }

    .lower-triangle {
      width: 19px;
      height: 20px;
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translate(-50%);
      border-top: 11px solid $color-active;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid transparent;
      display: none;
    }
  }
}
</style>
