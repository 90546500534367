<template>
  <section class="ProgressSection">
    <header>
      <h3>{{ section.title }}</h3>
    </header>
    <RateOfAdvance :list="section.data" />
  </section>
</template>

<script>
import RateOfAdvance from "./RateOfAdvance.vue";
export default {
  name: "ProgressSection",
  components: {
    RateOfAdvance,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.ProgressSection {
  header {
    margin-bottom: 16px;
  }
  h3 {
    font-size: 16px;
  }
}
</style>
