<template>
  <button class="EduButton" :class="type"><slot /></button>
</template>

<script>
export default {
  name: "EduButton",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.EduButton {
  padding: 12px 28px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid $color-border-0;
  background-color: $color-white;
  color: $color-text-5;
  font-size: 16px;
}

.primary {
  background-color: $color-active;
  color: $color-text-5;
}

.info {
  background-color: $color-background-3;
  color: $color-white;
}
</style>
