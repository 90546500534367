<template>
  <div class="myAccount">
    <!-- <div class="myAccount-title">我的账户</div> -->
    <div class="content">
      <div class="content-left">
        <div class="head" v-if="memberData">
          <img
          v-if="memberData.avatar"
            class="author-image"
            :src="memberData.avatar"
            alt=""
          />
          <div v-if="memberData" class="author-text">
            <p class="name" v-if="memberData.fullname">{{ memberData.fullname }}</p>
            <p class="date" v-if="expirationData != ''">有效期: {{ expirationData }}</p>
            <p class="date" v-else>您还未购买课程</p>
          </div>
        </div>
        <div class="buy-btn" v-if="expirationData == ''" @click="buy">立即购买</div>
        <div class="buy-btn" v-else @click="buy">立即续费</div>
        <div class="count-down" v-if="categoryData">{{categoryData.description}}<!--距离开考还剩 <span>5</span> 天--></div>
      </div>
      <div class="content-right">
        <div class="right-title">
          华路CPA高效备考工具包月畅学6门 省时省钱省力
        </div>
        <ul>
          <li 
            v-for="(item, i) in typeList" 
            :key="i" 
            @click="handleItemClick(item)">
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <!-- <div v-if="memberCourseData.length == 0" class="myAccount-more">
      <div>没有记录</div>
    </div> -->
    <pay v-show="isPay" ref="pay" @success="paySuccess" @close="closePay" />
  </div>
</template>

<script>
// import { getMemberCourse } from "@/api/memberController";
import {
  getMemberProfile
} from "@/api/memberController";
import {
  getCategoryById
} from "@/api/course";
import { getExpiration } from "@/api/memberController";
import pay from "@/views/Subject/components/Pay/index.vue";
export default {
  name: "myAccount",
  data() {
    return {
      activeIndex: 1,
      memberCourseData: [],
      memeberExpiredCourseData: [],
      memberData: null,
      expirationData: '',
      categoryData: {},
      isPay: false,
      typeList: [
        { title: "会计", name: 1 },
        { title: "税法", name: 2 },
        { title: "经济法", name: 3 },
        { title: "战略", name: 4 },
        { title: "审计", name: 5 },
        { title: "财管", name: 6 },
      ],
    };
  },
  components: {
    pay,
  },
  created() {
    this.getMemberProfile()
    this.getExpiration()
    this.getCategoryById()
  },
  methods: {
    closePay() {
      this.isPay = false;
    },
    paySuccess() {
      this.closePay();
      this.getExpiration();
    },
    buy() {
      this.isPay = true;
      this.$refs.pay.showType("list", "");
    },
    handleItemClick(item) {
      console.log("点我跳转!", item);
      // if (item.refKind == "COURSE") {
      //   this.$cookies.set("COURSE_ID", item.id, "30d");
      //   this.$router.push({
      //     path: "/Subject", //+ id
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/NewTopic" + item.id, //+ id
      //   });
      // }
    },
    getCategoryById() {
      getCategoryById( parent.CATEGORY_ID ).then((res) => {
        if (res.code == 20000) {
          this.categoryData = res.data
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getExpiration() {
      getExpiration({ categoryId: parent.CATEGORY_ID }).then((res) => {
        if (res.code == 20000) {
          this.expirationData = res.data
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getMemberProfile() {
      getMemberProfile().then((res) => {
        if (res.code == 20000) {
          this.memberData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.myAccount {
  padding: 52px 52px 80px 52px;
  .content {
    width: 1066px;
    height: 341px;
    background: #516e9d;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 10px 10px 10px 10px;
    // opacity: 0.75;
    display: flex;
    padding: 60px 34px 34px 34px;
    .content-left {
      margin-right: 120px;
      .head {
        display: flex;
        align-items: center;
        .author-image {
          width: 78px;
          height: 78px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #707070;
          border-radius: 5px;
        }
        .author-text {
          margin-left: 36px;
          .name {
            font-size: 30px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          .date {
            margin-top: 14px;
            font-size: 24px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            color: #ddd;
            line-height: 24px;
          }
        }
      }
      .buy-btn {
        margin-top: 23px;
        width: 338px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #ffcf00;
        border-radius: 10px 10px 10px 10px;
      }
      .count-down {
        margin-top: 18px;
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        span {
          font-size: 24px;
        }
      }
    }
    .content-right {
      width: 535px;
      .right-title {
        font-size: 24px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        margin-bottom: 20px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin: 6px;
          width: 30%;
          height: 70px;
          background: #173d7c;
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          font-size: 24px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 800;
          color: #ffffff;
          line-height: 70px;
          text-align: center;
        }
      }
    }
  }
  .myAccount-title {
    border-bottom: 2px solid #173d7c;
    font-size: 28px;
    font-weight: 400;
    color: #575b67;
    padding: 10px 0;
  }

  .myAccount-more {
    text-align: center;
    margin-top: 15px;
    div {
      display: inline-block;
      padding: 0 50px;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      &::after {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
      &::before {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
    }
  }
}
</style>
