<template>
  <div class="ProgressContent">
    <Tabs
      :tabs="tabs"
      :activeIndex.sync="activeIndex"
      @click="handleTabClick"
    />
    <ProgressSection
      v-for="(item, index) of list"
      :key="index"
      :section="item"
    />
  </div>
</template>

<script>
import Tabs from "./Tabs.vue";
import ProgressSection from "./ProgressSection.vue";
export default {
  name: "ProgressContent",
  components: {
    Tabs,
    ProgressSection,
  },
  data() {
    const advs = Array(6).fill({
      title: "调整期刊条目",
      percent: 100,
      progress: 30,
      desc: {
        endTime: "08/04/2021 01：00",
        startTime: "08/03/2021 06：02",
      },
    });
    const advs0 = [
      {
        title: "调整期刊条目",
        percent: 100,
      },
    ];
    return {
      activeIndex: 0,
      tabs: [
        { label: "CPA" },
        { label: "中级会计师" },
        { label: "税务师", disabled: true },
        { label: "保荐代表人", disabled: true },
      ],
      list: [
        {
          title: "HBS在线介绍",
          data: advs0,
        },
        {
          title: "财务会计",
          data: advs,
        },
      ],
    };
  },
  methods: {
    handleTabClick(index) {
      console.log(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.ProgressContent {
  padding: 30px 30px 110px 30px;

  ::v-deep {
    .ProgressSection {
      margin-top: 40px;
    }
  }
}
</style>
