<template>
  <div class="addCourse">
    <div class="addCourse-title">全部课程</div>
    <dl>
      <dd v-for="(courseItem, courseIndex) in courseData" :key="courseIndex">
        <div class="itemimg">
          <!-- <img src="~@/assets/course/img-item-1.png"> -->
          <img
            v-if="courseItem.coverImage != ''"
            :src="courseItem.coverImage"
          />
          <img v-else src="~@/assets/course/img-item-1.png" />
        </div>
        <div class="contertp30">
          <div class="infotitle">{{ courseItem.title }}</div>
          <div class="infoFont">{{ courseItem.subtitle }}</div>
          <!--<共6篇（29章）-->
          <div class="infoFont">
            <span v-html="courseItem.introduction"></span>
          </div>
          <div v-if="courseItem.published" class="centerInfoButton">
            <div
              class="infoButton1 on"
              @click="handleItemClick(courseItem, 'study')"
            >
              开始学习
            </div>
            <div
              class="infoButton1 on1"
              @click="handleItemClick(courseItem, 'practice')"
            >
              开始刷题
            </div>
          </div>
          <div v-else class="infoButton">即将上线</div>
        </div>
      </dd>
      <!-- <dd>
        <div class="itemimg"><img src="~@/assets/course/img-item-1.png"></div>
        <div class="contertp30">
          <div class="infotitle">CPA-会计</div>
          <div class="infoFont">共6篇（29章）</div>
          <div class="infoFont">注册会计师考试科目之一</div>
          <div class="infoButton">即将上线</div>
        </div>
      </dd>
      <dd>
        <div class="itemimg"><img src="~@/assets/course/img-item-1.png"></div>
        <div class="contertp30">
          <div class="infotitle">CPA-会计</div>
          <div class="infoFont">共6篇（29章）</div>
          <div class="infoFont">注册会计师考试科目之一</div>
          <div class="infoButton">即将上线</div>
        </div>
      </dd>
      <dd>
        <div class="itemimg"><img src="~@/assets/course/img-item-1.png"></div>
        <div class="contertp30">
          <div class="infotitle">CPA-会计</div>
          <div class="infoFont">共6篇（29章）</div>
          <div class="infoFont">注册会计师考试科目之一</div>
          <div class="infoButton">即将上线</div>
        </div>
      </dd>
      <dd>
        <div class="itemimg"><img src="~@/assets/course/img-item-1.png"></div>
        <div class="contertp30">
          <div class="infotitle">CPA-会计</div>
          <div class="infoFont">共6篇（29章）</div>
          <div class="infoFont">注册会计师考试科目之一</div>
          <div class="infoButton">即将上线</div>
        </div>
      </dd> -->
    </dl>
    <!-- <ul>
      <li
        v-for="(courseItem, courseIndex) in courseData"
        :key="courseIndex"
        @click="handleItemClick(courseItem)"
      >
        <div class="addCourse-item-left">
          <img
            v-if="courseItem.coverImage != ''"
            :src="courseItem.coverImage"
          />
          <img v-else src="~@/assets/course/vimg.jpg" />
        </div>
        <div class="addCourse-item-right">
          <div class="addCourse-item-title">
            <span>{{ courseItem.title }}</span>
            <i>{{ courseItem.created }}</i>
          </div>
          <div
            class="addCourse-item-font"
            v-html="courseItem.introduction"
          ></div>
        </div>
      </li>
    </ul> -->
    <div class="addCourse-more">
      <div>没有更多了</div>
    </div>
  </div>
</template>

<script>
import { getCourse } from "@/api/course.js";
export default {
  name: "addCourse",
  data() {
    return {
      activeIndex: 1,
      courseData: [],
    };
  },
  created() {
    this.getCourse();
  },
  methods: {
    getCourse() {
      getCourse({ categoryId: parent.CATEGORY_ID, __orderBy: "idx" }).then(
        (res) => {
          if (res.code == 20000) {
            this.courseData = res.data;
          } else {
            this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
          }
        }
      );
    },
    handleItemClick(course, type) {
      // if (this.activeIndex === index) {
      //   this.activeIndex = -1;
      // } else {
      //   this.activeIndex = index;
      // }
      //this.$store.commit("set_course_id", id);
      if (!course.published) {
        return;
      }
      //sessionStorage.setItem("COURSE_ID", course.id);
      this.$cookies.set("COURSE_ID", course.id);
      if (type == "study") {
        this.$router.push({
          path: "/Subject", //+ id
        });
      } else if (type == "practice") {
        this.$router.push({
          path: "/ExaminationCategory", //+ id
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.arts {
  height: 356px;
  overflow: auto;
  border: 1px solid $color-border-1;
}
.addCourse {
  padding: 18px 80px;
  .addCourse-title {
    border-bottom: 2px solid #173d7c;
    font-size: 28px;
    font-weight: 400;
    color: #575b67;
    padding: 10px 0;
  }
  dl {
    padding: 0;
    list-style: none;
    margin: 0 auto;
    &::after {
      display: block;
      clear: both;
      content: "";
    }
    dd {
      padding: 0;
      list-style: none;
      margin: 0 auto;
      width: calc(33.333333% - 18px);
      min-height: 328px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #aeaeae;
      float: left;
      margin-top: 20px;
      margin-right: 27px;
      cursor: pointer;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .itemimg {
        img {
          width: 100%;
        }
      }
      .contertp30 {
        padding: 5px 30px 20px 30px;
        .infotitle {
          text-align: center;
          font-size: 24px;
          font-weight: 400;
          color: #575b67;
          line-height: 36px;
          border-bottom: 1px solid #707070;
        }
        .infoFont {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          padding-top: 8px;
          height: 34px;
          overflow: hidden;
        }
        .infoButton {
          width: 138px;
          height: 38px;
          background: #777777;
          border-radius: 16px;
          border: 1px solid #5e5e5e;
          line-height: 38px;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          margin: 15px auto 0 auto;
          cursor: pointer;
          &.on {
            background: #173d7c;
            border: 1px solid #173d7c;
          }
        }
        .centerInfoButton {
          text-align: center;
          margin-top: 15px;
          .infoButton1 {
            width: 138px;
            height: 38px;
            background: #777777;
            border-radius: 16px;
            border: 1px solid #5e5e5e;
            line-height: 38px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            //margin: 15px auto 0 auto;
            //margin-top: 15px;
            cursor: pointer;
            margin: 0 25px;
            font-weight: 400;
            display: inline-block;
            &.on {
              background: #173d7c;
              border: 1px solid #173d7c;
            }
            &.on1 {
              background: #173d7c;
              border: 1px solid #173d7c;
              //margin-left: calc(33.333333% - 18px);
            }
          }
        }
      }
    }
  }
  ul {
    padding: 15px 0;
    li {
      padding: 20px 0;
      .addCourse-item-left {
        float: left;
        width: 220px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .addCourse-item-right {
        padding-left: 240px;
        .addCourse-item-title {
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          span {
            float: left;
            font-size: 24px;
            font-weight: 400;
            color: #575b67;
          }
          i {
            float: right;
            font-size: 16px;
            font-weight: 400;
            color: #173d7c;
            line-height: 24px;
            font-style: normal;
          }
          // &::after{
          //   clear: both;
          //   display: block;
          //   content: '';
          // }
        }
        .addCourse-item-font {
          margin-top: 15px;
          font-size: 16px;
          font-weight: 400;
          color: #060606;
          line-height: 24px;
          cursor: pointer;
        }
      }
      &::after {
        clear: both;
        display: block;
        content: "";
      }
    }
  }
  .addCourse-more {
    text-align: center;
    margin-top: 15px;
    div {
      display: inline-block;
      padding: 0 50px;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      &::after {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
      &::before {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
    }
  }
}
</style>
