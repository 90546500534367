import request from "@/utils/request";

const BASE_PATH = "/front/textbook/";
export function category() {
  return request({
    url: BASE_PATH + "category",
    method: "get",
  });
}

export function getCategoryById(_id) {
  return request({
    url: BASE_PATH + "category/" + _id,
    method: "get",
  });
}

export function getCourse(query) {
  return request({
    url: BASE_PATH + "course",
    method: "get",
    params: query,
  });
}

export function getCourseById(id) {
  return request({
    url: BASE_PATH + "course/" + id,
    method: "get",
  });
}

export function getCourseTreeById(id, query) {
  return request({
    url: BASE_PATH + "course/" + id + "/tree",
    method: "get",
    params: query,
  });
}
export function getCourseTree(query) {
  return request({
    url: BASE_PATH + "course/tree",
    method: "get",
    params: query,
  });
}

export function getCourseCount(query) {
  return request({
    url: BASE_PATH + "course/_count",
    method: "get",
    params: query,
  });
}
