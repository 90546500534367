<template>
  <div class="purchaseRecordBank">
    <div class="purchaseRecord">
      <ul>
        <li v-for="(orderItem, orderIndex) in orderDataList" :key="orderIndex">
          <div class="rTitle">
            <div class="fl">
              <!-- <span>01</span> -->
              <div>{{ orderItem.subject }}</div>
            </div>
            <div class="fr">
              <!-- <span>所属章：第十九章：财务报告</span> -->
              <div>¥{{ orderItem.amount / 100 }}</div>
            </div>
          </div>
          <div class="rCenter">
            <div
              v-if="orderItem.status == 1"
              @click="buy(orderItem.serialNum)"
              class="fr-button1"
            >
              <span>继续支付</span>
            </div>
            <div
              v-if="orderItem.status == 1"
              style="margin-right: 10px"
              class="fr-button1"
              @click="cancel(orderItem.serialNum)"
            >
              <span>取消</span>
            </div>
            <div v-else class="fr-button">
              <span v-if="orderItem.status == -3">退款</span>
              <span v-else-if="orderItem.status == -2">失败</span>
              <span v-else-if="orderItem.status == -1">已取消</span>
              <span v-else-if="orderItem.status == 0">未处理</span>
              <span v-else-if="orderItem.status == 2">已支付</span>
            </div>
            <div class="fl-centent">
              <div class="centent-font">订单号: {{ orderItem.serialNum }}</div>
              <div class="centent-time">{{ orderItem.created }}</div>
            </div>
          </div>
        </li>
        <!-- <li>
          <div class="rTitle">
            <div class="fl">
              <span>01</span>
              <div>第一节：实收资本</div>
            </div>
            <div class="fr">
              <span>所属章：第十九章：财务报告</span>
              <div>¥10</div>
            </div>
          </div>
          <div class="rCenter">
            <div class="fr-button">待支付</div>
            <div class="fl-centent">
              <div class="centent-font">
                股票期权作为企业管理中的一种激励手段，最早起源于美国，在上世纪70~80年代走
              </div>
              <div class="centent-time">2022/04/21 11:04</div>
            </div>
          </div>
        </li>
        <li>
          <div class="rTitle">
            <div class="fl">
              <span>01</span>
              <div>第一节：实收资本</div>
            </div>
            <div class="fr">
              <span>所属章：第十九章：财务报告</span>
              <div>¥10</div>
            </div>
          </div>
          <div class="rCenter">
            <div class="fr-button">待支付</div>
            <div class="fl-centent">
              <div class="centent-font">
                股票期权作为企业管理中的一种激励手段，最早起源于美国，在上世纪70~80年代走
              </div>
              <div class="centent-time">2022/04/21 11:04</div>
            </div>
          </div>
        </li>
        <li>
          <div class="rTitle">
            <div class="fl">
              <span>01</span>
              <div>第一节：实收资本</div>
            </div>
            <div class="fr">
              <span>所属章：第十九章：财务报告</span>
              <div>¥10</div>
            </div>
          </div>
          <div class="rCenter">
            <div class="fr-button">待支付</div>
            <div class="fl-centent">
              <div class="centent-font">
                股票期权作为企业管理中的一种激励手段，最早起源于美国，在上世纪70~80年代走
              </div>
              <div class="centent-time">2022/04/21 11:04</div>
            </div>
          </div>
        </li> -->
      </ul>
    </div>
    <div
      v-if="orderList != null && orderList.page + 1 >= orderList.totalPages"
      class="myStudy-more"
    >
      <div>没有更多了</div>
    </div>
    <div v-else class="myStudy-more1" @click="more">
      <div>更多</div>
    </div>
    <pay v-show="isPay" ref="pay" @success="paySuccess" @close="closePay" />
  </div>
</template>

<script>
import EduButton from "@/components/EduButton";
import { getOrder } from "@/api/orderController";
import { cancelOrder } from "@/api/orderController";
import pay from "@/views/Subject/components/Pay/index.vue";

export default {
  name: "purchaseRecordBank",
  components: {
    EduButton,
    pay,
  },
  created() {
    this.getOrder();
  },
  data() {
    return {
      orderList: null,
      queryForm: {
        __page: 0,
        __pageSize: 10,
        __orderBy: "-created",
      },
      orderDataList: [],
      isPay: false,
    };
  },
  methods: {
    getOrder() {
      getOrder(this.queryForm).then((res) => {
        if (res.code == 20000) {
          this.orderList = res;
          this.orderDataList = this.orderDataList.concat(res.data);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    buy(_orderId) {
      //this.backCType = this.cType;
      //this.cType = 3;
      this.isPay = true;
      this.$refs.pay.showType("pay", _orderId);
    },
    cancel(_orderId) {
      this.$confirm("您确认取消该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.cancelOrder(_orderId);
        })
        .catch(() => {});
    },
    cancelOrder(_orderId) {
      cancelOrder(_orderId).then((res) => {
        if (res.code == 20000) {
          this.orderDataList = [];
          this.queryForm.__page = 0;
          this.getOrder();
          this.$baseMessage(
            "订单取消成功",
            "success",
            "vab-hey-message-success"
          );
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    closePay() {
      this.isPay = false;
    },
    paySuccess() {
      this.closePay();
      this.orderDataList = [];
      this.queryForm.__page = 0;
      this.getOrder();
    },
    more() {
      this.queryForm.__page++;
      this.getOrder();
    },
  },
};
</script>

<style lang="scss" scoped>
.purchaseRecord {
  padding: 50px 80px;
  ul {
    border-top: 3px solid #707070;
    li {
      border-bottom: 1px solid #707070;
      padding: 15px 10px;
      &:hover {
        background: #f9f9f9;
        .rTitle {
          .fr {
            div {
              color: #ff4600;
            }
          }
        }
        .rCenter {
          .fr-button {
            color: #ff4600;
            border: 1px solid #ff4600;
          }
        }
      }
      .rTitle {
        line-height: 36px;
        .fl {
          float: left;
          span {
            float: left;
            display: block;
            width: 24px;
            font-size: 20px;
            font-weight: 400;
            color: #575b67;
          }
          div {
            padding-left: 24px;
            font-size: 20px;
            font-weight: bold;
            color: #575b67;
          }
        }
        .fr {
          float: right;
          span {
            float: left;
            font-size: 16px;
            font-weight: 400;
            color: #adadad;
          }
          div {
            float: left;
            margin-left: 50px;
            font-size: 16px;
            font-weight: bold;
            color: #575b67;
          }
        }
        &::after {
          clear: both;
          display: block;
          content: "";
        }
      }
      .rCenter {
        padding-left: 24px;
        .fr-button {
          float: right;
          min-width: 132px;
          height: 46px;
          background: #ffffff;
          border-radius: 25px;
          opacity: 1;
          border: 1px solid #707070;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          color: #575b67;
          line-height: 46px;
          margin-top: 15px;
          box-sizing: content-box;
          &:hover {
            color: #ff4600;
            border: 1px solid #ff4600;
          }
        }
        .fr-button1 {
          float: right;
          min-width: 132px;
          height: 46px;
          background: #173d7c;
          border-radius: 25px;
          opacity: 1;
          border: 1px solid #707070;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          line-height: 46px;
          margin-top: 15px;
          box-sizing: content-box;
          &:hover {
            color: #ff4600;
            border: 1px solid #ff4600;
          }
        }
        .fl-centent {
          padding-right: 130px;
          .centent-font {
            font-size: 16px;
            font-weight: 400;
            color: #181818;
            line-height: 24px;
            padding-top: 10px;
          }
          .centent-time {
            font-size: 16px;
            font-weight: 400;
            color: #7e7e7e;
            line-height: 24px;
            padding-top: 10px;
          }
        }
        &::after {
          clear: both;
          display: block;
          content: "";
        }
      }
      &::after {
        clear: both;
        display: block;
        content: "";
      }
    }
  }
}
.list {
  margin: 18px 18px 33px 18px;
  border: 1px solid $color-border-1;
  height: 225px;
  overflow: auto;
  .item {
    border-bottom: 1px solid $color-border-1;
    padding: 16px 32px 18px;
    background-color: $color-background-1;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 3px;
    }

    p {
      font-size: 12px;
    }
    &:hover {
      background-color: $color-background-2;
    }
  }
}

.timeout {
  border-top: 1px solid $color-border-1;
  padding-bottom: 45px;
  .desc {
    font-weight: bold;
    text-align: center;
    padding: 35px 0 54px;
  }
}

.btns {
  text-align: center;

  button {
    margin: 0 10px;
  }
}
.purchaseRecord-tabs {
  ul {
    li {
      float: left;
      margin-left: 1px;
      min-width: 182px;
      height: 50px;
      background: #f1f1f1;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #cccccc;
      padding: 0 15px;
      box-sizing: content-box;
      text-align: center;
      line-height: 50px;
      position: relative;
      font-size: 16px;
      font-weight: 300;
      color: #707070;
      font-weight: bold;
      cursor: pointer;
      &.on,
      &:hover {
        width: 182px;
        height: 50px;
        background: #f3c912;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #f3c912;
        color: #333333;
        &::after {
          position: absolute;
          left: 50%;
          margin-left: -10px;
          border: 10px solid transparent;
          border-top: 10px solid #f3c912;
          bottom: -20px;
          content: "";
          display: block;
        }
      }
    }
  }
  &::after {
    clear: both;
    display: block;
    content: "";
  }
}
.mt35 {
  margin-top: 35px;
}
.myStudy-more {
  text-align: center;
  margin-top: -15px;
  margin-bottom: 25px;
  div {
    display: inline-block;
    padding: 0 20px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    &::after {
      position: absolute;
      display: block;
      content: "";
      left: 0;
      top: 13px;
      height: 1px;
      width: 40px;
      background: #666666;
    }
    &::before {
      position: absolute;
      display: block;
      content: "";
      right: 0;
      top: 13px;
      height: 1px;
      width: 40px;
      background: #666666;
    }
  }
}
.myStudy-more1 {
  text-align: center;
  margin-top: -15px;
  margin-bottom: 25px;
  div {
    //display: inline-block;
    padding: 0 20px;
    //position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    &::after {
      position: absolute;
      display: block;
      content: "";
      left: 0;
      top: 13px;
      height: 1px;
      width: 40px;
      background: #666666;
    }
    &::before {
      position: absolute;
      display: block;
      content: "";
      right: 0;
      top: 13px;
      height: 1px;
      width: 40px;
      background: #666666;
    }
  }
}
</style>
