import request from "@/utils/request";

const BASE_PATH = "/front/billing/order/";
export function cancelOrder(orderId, data) {
  return request({
    url: BASE_PATH + orderId + "/cancel",
    method: "post",
    data,
  });
}

export function getOrderDetail(orderId, query) {
  return request({
    url: BASE_PATH + orderId + "/detail",
    method: "get",
    params: query,
  });
}

export function repayOrder(orderId, data) {
  return request({
    url: BASE_PATH + orderId + "/repay",
    method: "post",
    data
  });
}

export function getOrderStatus(orderId, query) {
  return request({
    url: BASE_PATH + orderId + "/status",
    method: "get",
    params: query,
  });
}

export function createOrder(data) {
  return request({
    url: BASE_PATH + "/pre-create",
    method: "post",
    data,
  });
}

export function submitOrder(data) {
  return request({
    url: BASE_PATH + "/submit-order",
    method: "post",
    data,
  });
}

export function getOrder(query){
  return request({
    url: BASE_PATH ,
    method: "get",
    params: query,
  });
}

