<template>
  <div class="myCourse">
    <!-- <div class="myCourse-title">我的课程</div> -->
    <div v-for="(dataItem, dataIndex) in data" :key="dataIndex">
      <div
        v-if="dataItem.segmentTitle != null || dataItem.examTitle != null"
        style="
          font-size: 28px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-top: 20px;
        "
      >
        会计
      </div>
      <ul>
        <li
          v-if="dataItem.segmentTitle != null"
          @click="handleItemClick('course', dataItem)"
        >
          <div
            style="
              padding-left: 21px;
              padding-top: 32px;
              padding-bottom: 32px;
              padding-right: 21px;
            "
          >
            <div class="myCourse-item-left">
              <img
                v-if="
                  dataItem.courseImage != '' && dataItem.courseImage != null
                "
                :src="dataItem.courseImage"
              />
              <img v-else src="~@/assets/course/vimg.jpg" />
            </div>
            <div class="myCourse-item-right">
              <div class="myCourse-item-title">
                <div>{{ dataItem.courseTitle }}</div>
                <div>
                  {{ dataItem.partTitle }} {{ dataItem.chapterTitle }}
                  {{ dataItem.sectionTitle }}
                </div>
                <div>{{ dataItem.segmentTitle }}</div>
                <!-- <i>{{ courseItem.created }}</i> -->
              </div>
              <div class="myCourse-item-font">
                {{ dataItem.courseLastVisit }}
              </div>
            </div>
          </div>
        </li>
        <li
          style="margin-top: 20px"
          v-if="dataItem.examTitle != null"
          @click="handleItemClick('exam', dataItem)"
        >
          <div
            style="
              padding-left: 21px;
              padding-top: 32px;
              padding-bottom: 32px;
              padding-right: 21px;
            "
          >
            <div class="myCourse-item-left">
              <img
                v-if="dataItem.examImage != '' && dataItem.examImage != null"
                :src="dataItem.examImage"
              />
              <img v-else src="~@/assets/course/vimg.jpg" />
            </div>
            <div class="myCourse-item-right">
              <div class="myCourse-item-title">
                <div>{{ dataItem.courseTitle }}</div>
                <div>{{ dataItem.examTitle }}</div>
                <!-- <i>{{ courseItem.created }}</i> -->
              </div>
              <div class="myCourse-item-font">{{ dataItem.examLastVisit }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="data.length == 0" class="myCourse-more">
      <div>没有记录</div>
    </div>
  </div>
</template>

<script>
import { getVisitsList } from "@/api/memeberCourseVisits";
export default {
  name: "myCourse",
  data() {
    return {
      activeIndex: 1,
      data: [],
      queryForm: {
        __page: 0,
        __pageSize: 10,
        __orderBy: "-created",
      },
    };
  },
  created() {
    this.getVisitsList();
  },
  methods: {
    getVisitsList() {
      getVisitsList(this.queryForm).then((res) => {
        //Valid
        if (res.code == 20000) {
          this.data = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    // getMemberCourseExpired() {
    //   getMemberCourse({ type: "Expired" }).then((res) => {
    //     if (res.code == 20000) {
    //       this.memeberExpiredCourseData = res.data;
    //     } else {
    //       this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
    //     }
    //   });
    // },
    handleItemClick(type, data) {
      // if (this.activeIndex === index) {
      //   this.activeIndex = -1;
      // } else {
      //   this.activeIndex = index;
      // }
      //sessionStorage.setItem("COURSE_ID", id);
      this.$cookies.set("COURSE_ID", data.courseId, "30d");
      if (type == "course") {
        this.$router.push({
          path: "/Subject",
          query: {
            segmentId: data.segmentId,
            action: "refresh",
            keyword: "",
          },
        });
      } else {
        var path = "Exercise";
        if (data.examType == "Exam") {
          path = "NewTopic";
        }
        this.$router.push({
          path: "/" + path + "/" + data.examId,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.arts {
  height: 356px;
  overflow: auto;
  border: 1px solid $color-border-1;
}
.myCourse {
  padding: 18px 80px;
  .myCourse-title {
    border-bottom: 2px solid #173d7c;
    font-size: 28px;
    font-weight: 400;
    color: #575b67;
    padding: 10px 0;
  }
  ul {
    padding: 15px 0;
    li {
      background: #f9f9f9;

      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      //padding: 20px 0;
      padding-bottom: 20px;
      .myCourse-item-left {
        float: left;
        width: 220px;
        img {
          width: 100%;
        }
      }
      .myCourse-item-right {
        float: left;
        margin-left: 20px;
        .myCourse-item-title {
          height: 86px;
          margin-top: 5px;
          div {
            line-height: 28px;
            font-size: 20px;
            font-weight: 400;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            color: #171717;
          }
          // i {
          //   float: right;
          //   font-size: 16px;
          //   font-weight: 400;
          //   color: #173d7c;
          //   line-height: 24px;
          //   font-style: normal;
          // }
          // &::after{
          //   clear: both;
          //   display: block;
          //   content: '';
          // }
        }
        .myCourse-item-font {
          margin-top: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #060606;
          line-height: 24px;
        }
      }
      &::after {
        clear: both;
        display: block;
        content: "";
      }
    }
  }
  .myCourse-more {
    text-align: center;
    margin-top: 15px;
    div {
      display: inline-block;
      padding: 0 50px;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      &::after {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
      &::before {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
    }
  }
}
</style>
