<template>
  <div class="HomeCards">
    <HomeCardItem v-for="(item, index) of list" :key="index" :obj="item" />
  </div>
</template>

<style lang="scss" scoped>
.HomeCards {
  margin-top: 40px;
  // width: 1200px;
  display: flex;
  justify-content: space-between;
}
</style>

<script>
import HomeCardItem from "./HomeCardItem.vue";
import { category } from "@/api/course";
export default {
  name: "HomeCards",
  components: {
    HomeCardItem,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.categoryFn();
  },
  methods: {
    async categoryFn() {
      const { code, data } = await category();
      if (code == 20000) {
        this.list = data;
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
  },
};
</script>
