<template>
  <div class="EduHome">
    <div v-if="type == 'list'" class="NewTopicShow">
      <div class="NewTopicInfo">
        <div class="close" @click="closeProductList('list')"></div>
        <div class="infoTitle">
          <div class="title">华路CPA高效学习工具包月畅学</div>
          <div class="title-font">省时省力省钱</div>
        </div>
        <div class="NewTopic-ul">
          <ul>
            <li
              v-for="(productItem, productIndex) in productList"
              :key="productIndex"
            >
              <el-row>
                <!-- <el-col :span="5" class="lh50bf">
                        <label>
                          <input type="radio" name="rm1" />
                          1个视频
                        </label>
                      </el-col> -->
                <el-col :span="9">
                  <div class="font-1">{{ productItem.name }}</div>
                  <!-- <div class="font-time">
                    有效期：
                    <span>{{ addMonths(productItem.validMonth) }}</span>
                  </div> -->
                </el-col>
                <el-col :span="3" class="lh50">
                  <div v-if="productItem.price">
                    ￥{{ productItem.price / 100 }}
                  </div></el-col
                >
                <el-col :span="7" class="lhTag50">
                  <div>
                    {{ productItem.priceTag }}
                  </div></el-col
                >
                <el-col :span="5">
                  <div class="gmBut" @click="pay(productIndex)">
                    <span v-if="productItem.kind == 'BOUQUET'">购买产品包</span>
                    <span v-else> 立即购买 </span>
                  </div>
                </el-col>
              </el-row>
            </li>
          </ul>
        </div>

        <div class="protocol">
          <div>
            <label><input v-model="checkedInfo" type="checkbox" /></label
            >我已阅读同意<span
              ><span @click="payPact">《购买协议》</span>
            </span>
          </div>
          <!-- <div>新版《用户协议》已经更新，请您及时查看</div> -->
        </div>
      </div>
    </div>

    <div v-if="type == 'pay'" class="NewPayShow">
      <div class="NewTopicInfo">
        <div class="close" @click="closeProductList('pay')"></div>
        <div class="infoTitle">
          <div class="title">华路CPA高效学习工具包月畅学</div>
          <div class="title-font">省时省力省钱</div>
        </div>
        <div class="title-tip">
          注意:如已扫描支付，请勿重复扫描支付，若长时间未返回订购成功，可去购买记录查看结果!
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 20px;
          "
        >
          <img src="~@/assets/outline/weipaylogo.png" />
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          "
        >
          <div class="warBr">
            <img v-if="scanningCodeUrl" :src="scanningCodeUrl" />
            <span v-else>正在创建订单...</span>
            <!-- <img  src="~@/assets/code.png" /> -->
          </div>
        </div>
        <div
          v-if="payData && payData.order"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 20px;
          "
        >
          应付金额:<span style="color: #173d7c; font-weight: bold"
            >￥{{ payData.order.amount / 100 }}</span
          >
        </div>
      </div>
    </div>
    <protocol-dialog ref="protocolDialog"></protocol-dialog>
  </div>
</template>
<script>
import { getProductBouque } from "@/api/product";
import { addMonths } from "@/utils/validate";
import {
  createOrder,
  submitOrder,
  //getOrderStatus,
  getOrderDetail,
  repayOrder,
} from "@/api/orderController";
import ProtocolDialog from "@/views/Login/components/ProtocolDialog.vue";

export default {
  name: "ColdCall",
  data() {
    return {
      productList: [],
      productIndex: 0,
      orderStatusTimer: -1,
      type: "list",
      scanningCodeUrl: "",
      payData: {},
      checkedInfo: false,
    };
  },
  created() {},
  props: {},
  watch: {},
  beforeDestroy() {},
  methods: {
    addMonths(_validMonth) {
      var mills = new Date().getTime();
      return addMonths(mills, _validMonth);
    },
    showType(_type, _orderId) {
      this.type = _type;
      this.scanningCodeUrl = "";
      if (this.type == "pay") {
        repayOrder(_orderId).then((res) => {
          if (res.code == 20000) {
            this.payData = res.data;
            this.scanningCodeUrl = res.data.qrImage;
            this.getOrderDetail(res.data.order.serialNum);
          } else {
            this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
          }
        });
      } else {
        this.productList = [];
        this.getProductBouque();
      }
    },
    closeProductList() {
      window.clearTimeout(this.orderStatusTimer);
      this.$emit("close");
    },
    repayOrder(_orderId) {
      repayOrder(_orderId);
    },
    getProductBouque() {
      let params = {
        published: true,
        __orderBy: "featured",
        categoryId: parent.CATEGORY_ID,
        __page: 0,
        __pageSize: 999,
      };
      getProductBouque(params).then((res) => {
        if (res.code == 20000) {
          this.productList = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    payPact() {
      this.$refs["protocolDialog"].showEdit("course");
    },
    pay(index) {
      if (!this.checkedInfo) {
        this.$baseMessage(
          "请先阅读协议并勾选协议",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      this.type = "pay";
      let product = this.productList[index];
      let orderData = {
        items: [
          {
            count: 1,
            kind: "",
            name: "",
            refId: 0,
          },
        ],
        subject: "",
      };
      orderData.subject = product.name;
      orderData.items[0].kind = "BOUQUET";
      orderData.items[0].refId = product.id;
      orderData.items[0].name = product.name;
      this.scanningCodeUrl = "";
      createOrder(orderData).then((res) => {
        if (res.code == 20000) {
          this.submitOrder(res.data);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
      this.productIndex = index;
    },
    submitOrder(data) {
      submitOrder(data).then((res) => {
        if (res.code == 20000) {
          this.scanningCodeUrl = res.data.qrImage;
          this.payData = res.data;
          this.getOrderDetail(res.data.order.serialNum);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getOrderDetail(serialNum) {
      window.clearTimeout(this.orderStatusTimer);
      getOrderDetail(serialNum).then((res) => {
        if (res.code == 20000) {
          let status = res.data.status;
          // -3 退款 -2 失败， -1 取消， 0 未处理 1 支付中 2 已支付
          if (status == -3) {
            this.$baseMessage(
              "此订单已退款!",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == -2) {
            this.$baseMessage(
              "订单已失败!请稍后再试",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == 1) {
            let that = this;
            this.orderStatusTimer = setTimeout(function () {
              that.getOrderDetail(serialNum);
            }, 1000);
          } else if (status == 0) {
            this.$baseMessage(
              "订单未处理!请稍后再试",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == 2) {
            this.$baseMessage(
              "恭喜您已订购成功！",
              "success",
              "vab-hey-message-success"
            );
            this.$emit("success");
            //this.productList.splice(this.productIndex, this.productList.length);
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
  },
  components: {
    ProtocolDialog,
  },
};
</script>

<style lang="scss" scoped>
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}

.NewTopicShow {
  background: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 80px 30px 20px 30px;
  .NewTopicInfo {
    border-radius: 6px;
    background: #ffffff;
    width: 830px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    .infoTitle {
      margin: 0 35px;
      padding: 15px 20px;
      background: #173d7c;
      color: #ffffff;
      border-radius: 6px;
      text-align: center;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        line-height: 50px;
      }
      .title-font {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        padding-bottom: 10px;
      }
    }

    .NewTopic-ul {
      //padding-top: 30px;
      padding: 30px 30px 20px 30px;
      width: 830px;
      ul {
        li {
          margin-top: 10px;
          padding: 12px 30px;
          border-radius: 6px;
          border: 1px solid #666666;
          &::after {
            clear: both;
            display: block;
            content: "";
          }
          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }
    .protocol {
      width: 830px;
      font-size: 16px;
      color: #666666;
      line-height: 24px;
      text-align: center;
      span {
        color: #173d7c;
      }
    }
    .close {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: url("~@/assets/icon-gb.png") no-repeat center;
      background-size: cover;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.gmBut {
  margin-left: 20px;
  height: 50px;
  background: #173d7c;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
}
.font-1 {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 50px;
}
.font-time {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.lh50 {
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;

  color: #ff4600;
  span {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-left: 15px;
    font-weight: normal;
  }
}
.lhTag50 {
  margin-top: 12.5px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 25px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-radius: 50px;
  background: #a41034;
}
.NewPayShow {
  background: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 80px 30px 20px 30px;
  .NewTopicInfo {
    border-radius: 6px;
    background: #ffffff;
    width: 830px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    .infoTitle {
      margin: 0 35px;
      padding: 15px 20px;
      background: #173d7c;
      color: #ffffff;
      border-radius: 6px;
      text-align: center;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        line-height: 50px;
      }
      .title-font {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        padding-bottom: 10px;
      }
    }
    .title-tip {
      margin: 0 35px;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: 400;
      color: #173d7c;
      padding-bottom: 10px;
      text-align: center;
    }
    .warBr {
      border: 1px solid #cccccc;
      padding: 0px;
      margin-top: 20px;
      text-align: center;
      width: 40%;
      img {
        width: 100%;
      }
    }
    .warBr-title {
      text-align: center;
      line-height: 36px;
    }
    .NewTopic-ul {
      padding-top: 30px;
      ul {
        li {
          padding: 12px 30px;
          &::after {
            clear: both;
            display: block;
            content: "";
          }
          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }
    .close {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: url("~@/assets/icon-gb.png") no-repeat center;
      background-size: cover;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
