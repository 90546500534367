import request from "@/utils/request";

const BASE_PATH = "/front/member/visits/";
export function getVisitsCourse() {
  return request({
    url: BASE_PATH + "course",
    method: "get",
  });
}
export function postVisitsCourse(data) {
  return request({
    url: BASE_PATH + "course",
    method: "post",
    data,
  });
}

export function getVisitsList(query) {
  return request({
    url: BASE_PATH + "list",
    method: "get",
    query,
  });
}
