<template>
  <div class="myCourse">
    <!-- <div class="myCourse-title">我的课程</div> -->
    <ul>
      <li
        v-for="(courseItem, courseIndex) in memberCourseData"
        :key="courseIndex"
        @click="handleItemClick(courseItem)"
      >
        <div
          style="
            padding-left: 21px;
            padding-top: 32px;
            padding-bottom: 32px;
            padding-right: 21px;
          "
        >
          <div class="myCourse-item-left">
            <img
              v-if="
                courseItem.courseCover != '' && courseItem.courseCover != null
              "
              :src="courseItem.courseCover"
            />
            <img v-else src="~@/assets/course/vimg.jpg" />
          </div>
          <div class="myCourse-item-right">
            <div class="myCourse-item-title">
              <span>{{ courseItem.courseTitle }}</span>
              <!-- <i>{{ courseItem.created }}</i> -->
            </div>
            <div class="myCourse-item-font">
              有效期至: {{ courseItem.expires }}
            </div>
          </div>
          <div class="myCourse-item-right-button">
            <div class="purchaseItemButton">学习</div>
          </div>
        </div>
      </li>
      <!-- <li>
        <div class="myCourse-item-left">
          <img src="~@/assets/course/vimg.jpg" />
        </div>
        <div class="myCourse-item-right">
          <div class="myCourse-item-title">
            <span>会计</span>
            <i>2022/04/21</i>
          </div>
          <div class="myCourse-item-font">
            您是竞争激烈的行业的上市公司的首席财务官。您正在准备年度报告和 SEC
            文件，您正在仔细考虑提供多少信息。您担心，如果您提供太多细节，您的竞争对手可能会获得一些优势，但您知道投资者想要更多细节，以便他们能够评估业务（和管理）绩效。您如何处理这些相互矛盾的元素？
          </div>
        </div>
      </li>
      <li>
        <div class="myCourse-item-left">
          <img src="~@/assets/course/vimg.jpg" />
        </div>
        <div class="myCourse-item-right">
          <div class="myCourse-item-title">
            <span>会计</span>
            <i>2022/04/21</i>
          </div>
          <div class="myCourse-item-font">
            您是竞争激烈的行业的上市公司的首席财务官。您正在准备年度报告和 SEC
            文件，您正在仔细考虑提供多少信息。您担心，如果您提供太多细节，您的竞争对手可能会获得一些优势，但您知道投资者想要更多细节，以便他们能够评估业务（和管理）绩效。您如何处理这些相互矛盾的元素？
          </div>
        </div>
      </li>
      <li>
        <div class="myCourse-item-left">
          <img src="~@/assets/course/vimg.jpg" />
        </div>
        <div class="myCourse-item-right">
          <div class="myCourse-item-title">
            <span>会计</span>
            <i>2022/04/21</i>
          </div>
          <div class="myCourse-item-font">
            您是竞争激烈的行业的上市公司的首席财务官。您正在准备年度报告和 SEC
            文件，您正在仔细考虑提供多少信息。您担心，如果您提供太多细节，您的竞争对手可能会获得一些优势，但您知道投资者想要更多细节，以便他们能够评估业务（和管理）绩效。您如何处理这些相互矛盾的元素？
          </div>
        </div>
      </li> -->
    </ul>
    <div v-if="memberCourseData.length == 0" class="myCourse-more">
      <div>没有记录</div>
    </div>
    <!-- <div class="myCourse-title">过期课程</div>
    <ul>
      <li
        v-for="(courseItem, courseIndex) in memeberExpiredCourseData"
        :key="courseIndex"
        @click="handleItemClick(courseItem.id)"
      >
        <div class="myCourse-item-left">
          <img
            v-if="
              courseItem.courseCover != '' && courseItem.courseCover != null
            "
            :src="courseItem.courseCover"
          />
          <img v-else src="~@/assets/course/vimg.jpg" />
        </div>
        <div class="myCourse-item-right">
          <div class="myCourse-item-title">
            <span>{{ courseItem.courseTitle }}</span>
            <i>{{ courseItem.created }}</i>
          </div>
          <div class="myCourse-item-font" v-html="courseItem.courseIntro"></div>
        </div> 
      </li>
    </ul>-->
    <!-- <div class="myCourse-more">
      <div>没有更多了</div>
    </div> -->
    <!-- <div v-if="memeberExpiredCourseData.length == 0" class="myCourse-more">
      <div>没有记录</div>
    </div> -->
  </div>
</template>

<script>
import { getMemberCourse } from "@/api/memberController";
export default {
  name: "myCourse",
  data() {
    return {
      activeIndex: 1,
      memberCourseData: [],
      memeberExpiredCourseData: [],
    };
  },
  created() {
    this.getMemberCourse();
    //this.getMemberCourseExpired();
  },
  methods: {
    getMemberCourse() {
      getMemberCourse({ type: "Valid" }).then((res) => {
        if (res.code == 20000) {
          this.memberCourseData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getMemberCourseExpired() {
      getMemberCourse({ type: "Expired" }).then((res) => {
        if (res.code == 20000) {
          this.memeberExpiredCourseData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    handleItemClick(item) {
      // if (this.activeIndex === index) {
      //   this.activeIndex = -1;
      // } else {
      //   this.activeIndex = index;
      // }
      //sessionStorage.setItem("COURSE_ID", id);

      if (item.refKind == "COURSE") {
        this.$cookies.set("COURSE_ID", item.id, "30d");
        this.$router.push({
          path: "/Subject", //+ id
        });
      } else {
        this.$router.push({
          path: "/NewTopic" + item.id, //+ id
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.arts {
  height: 356px;
  overflow: auto;
  border: 1px solid $color-border-1;
}
.myCourse {
  padding: 18px 80px;
  .myCourse-title {
    border-bottom: 2px solid #173d7c;
    font-size: 28px;
    font-weight: 400;
    color: #575b67;
    padding: 10px 0;
  }
  ul {
    padding: 15px 0;
    li {
      background: #f9f9f9;
      margin-top: 20px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      //padding: 20px 0;
      padding-bottom: 20px;
      .myCourse-item-left {
        float: left;
        width: 220px;
        img {
          width: 100%;
        }
      }
      .myCourse-item-right {
        float: left;
        margin-left: 20px;
        width: 500px;
        .myCourse-item-title {
          height: 30px;
          line-height: 30px;
          margin-top: 20px;
          span {
            float: left;
            font-size: 24px;
            font-weight: 400;
            color: #575b67;
          }
          // i {
          //   float: right;
          //   font-size: 16px;
          //   font-weight: 400;
          //   color: #173d7c;
          //   line-height: 24px;
          //   font-style: normal;
          // }
          // &::after{
          //   clear: both;
          //   display: block;
          //   content: '';
          // }
        }
        .myCourse-item-font {
          margin-top: 50px;
          font-size: 16px;
          font-weight: 400;
          color: #060606;
          line-height: 24px;
        }
      }
      .myCourse-item-right-button {
        margin-top: 55px;
        float: right;
        width: 130px;
        .purchaseItemButton {
          cursor: pointer;
          width: 130px;
          height: 50px;
          background: #173d7c;
          border-radius: 10px;
          text-align: center;
          line-height: 50px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }
      &::after {
        clear: both;
        display: block;
        content: "";
      }
    }
  }
  .myCourse-more {
    text-align: center;
    margin-top: 15px;
    div {
      display: inline-block;
      padding: 0 50px;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      &::after {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
      &::before {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        top: 13px;
        height: 1px;
        width: 40px;
        background: #666666;
      }
    }
  }
}
</style>
