<template>
  <div class="myTimeBank">
    <div class="timeInfo">
      <div class="timeInfo-tabs">
        <ul>
          <li
            v-for="(courseItem, courseIndex) in memberCourseData"
            :key="courseIndex"
            @click="handleItemClick(courseIndex)"
            :class="{ on: courseIndex == activeIndex }"
          >
            {{ courseItem.courseTitle }}
          </li>
          <!-- <li>审计</li>
          <li>财务管理</li>
          <li>经济法</li>
          <li>税法</li>
          <li>战略</li> -->
        </ul>
      </div>
      <div class="mt35">
        <div class="timeInfo-title">我的时间银行</div>
        <div class="timeInfo-ul">
          <ul>
            <li class="lh64">
              <span>● 课程还有时间</span>
              <div>{{ this.day }}</div>
              <span>天</span>
              <div>{{ this.hour }}</div>
              <span>时</span>
              <div>{{ this.minute }}</div>
              <span>分</span>
              <div>{{ this.second }}</div>
              <span>秒</span>
            </li>
            <!--<li>
              ● 您的课程只剩下<i>7</i>天。
               <span class="aHred"
                >推荐购买更多课程充值时间</span
              > 
            </li>-->
            <!-- <li>
              ● 根据您的报告统计出的知识点错误<span class="aHred"
                >推荐您购买第三章</span
              >
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="list">
      <div class="item" v-for="(item, index) of list" :key="index">
        <h3>{{ item.title }}</h3>
        <p>{{ item.time }}</p>
      </div>
    </div>
    <div class="timeout">
      <div class="desc">
        你购买的课程还有3天就要过期了，如有需要继续课程请尽快充值。
      </div>
      <div class="btns">
        <EduButton type="primary">立即购买</EduButton>
        <EduButton type="info">返回课程</EduButton>
      </div>
    </div> -->
  </div>
</template>

<script>
import EduButton from "@/components/EduButton";
import { getMemberCourse } from "@/api/memberController.js";
export default {
  name: "myTimeBank",
  components: {
    EduButton,
  },
  created() {
    this.getMemberCourse();
  },
  data() {
    const list = Array(5).fill({
      title: "课时倒计时",
      time: "2021年8月21日下午01:20东部时间",
    });
    return {
      list,
      memberCourseData: [],
      activeIndex: 0,
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
      timeout: -1,
    };
  },
  beforeDestroy() {
    window.clearTimeout(this.timeout);
  },
  methods: {
    getMemberCourse() {
      getMemberCourse({ type: "Valid" }).then((res) => {
        if (res.code == 20000) {
          this.memberCourseData = res.data;
          this.showExpiration();
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    showExpiration() {
      let second =
        (new Date(this.memberCourseData[this.activeIndex].expires).getTime() -
          new Date().getTime()) /
        1000;
      let data = this.dateCalculation(second);
      this.day = data.day;
      this.hour = data.hour;
      this.minute = data.minute;
      this.second = data.second;
      let that = this;
      this.timeout = setTimeout(function () {
        that.showExpiration();
      }, 1000);
    },
    dateCalculation(_second) {
      if (_second > 0) {
        var day = 0;
        var hour = 0;
        var minute = 0;
        var second = 0;
        var data = {};
        second = parseInt(_second % 60);
        data.second = second;
        minute = Math.floor(_second / 60);
        if (parseInt(minute) > 60) {
          hour = parseInt(minute / 60);
          minute %= 60; //算出有多分钟
        }
        if (parseInt(hour) > 24) {
          day = parseInt(hour / 24);
          hour %= 24; //算出有多少小时
        }
        data.day = day;
        data.hour = hour;
        data.minute = minute;
        return data;
      }
    },
    handleItemClick(index) {
      this.activeIndex = index;
      window.clearTimeout(this.timeout);
      this.showExpiration();
    },
  },
};
</script>

<style lang="scss" scoped>
.timeInfo {
  padding: 50px 80px;
  .timeInfo-title {
    font-weight: bold;
    line-height: 48px;
    font-size: 28px;
    font-weight: 400;
    color: #575B67;
  }
  .timeInfo-ul {
    padding: 15px 0;
    font-size: 16px;
    li {
      clear: both;
      position: relative;
      color: #575b67;
      line-height: 40px;
      padding: 5px 0;
      &.lh64 {
        line-height: 64px;
        span {
          float: left;
          display: block;
        }
        div {
          display: block;
          height: 64px;
          background: #ffcf00;
          border-radius: 6px;
          border: 1px solid #707070;
          padding: 0 15px;
          float: left;
          font-size: 60px;
          margin-left: 15px;
          margin-right: 10px;
          line-height: 64px;
          font-weight: bold;
          color: #090909;
        }
      }
      i {
        color: #173d7c;
        font-style: normal;
        font-weight: bold;
      }
      .aHred {
        font-size: 16px;
        font-weight: bold;
        color: #173d7c;
        text-decoration: underline;
        margin-left: 15px;
        cursor: pointer;
      }
      // &::before{
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   display: block;
      // }
      &::after {
        clear: both;
        display: block;
        content: "";
      }
    }
  }
}
.list {
  margin: 18px 18px 33px 18px;
  border: 1px solid $color-border-1;
  height: 225px;
  overflow: auto;
  .item {
    border-bottom: 1px solid $color-border-1;
    padding: 16px 32px 18px;
    background-color: $color-background-1;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 3px;
    }

    p {
      font-size: 12px;
    }
    &:hover {
      background-color: $color-background-2;
    }
  }
}

.timeout {
  border-top: 1px solid $color-border-1;
  padding-bottom: 45px;
  .desc {
    font-weight: bold;
    text-align: center;
    padding: 35px 0 54px;
  }
}

.btns {
  text-align: center;

  button {
    margin: 0 10px;
  }
}
.timeInfo-tabs{
  ul{
    li{
      float: left;
      margin-left: 1px;
      min-width: 182px;
      height: 50px;
      background: #F1F1F1;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #CCCCCC;
      padding: 0 15px;
      box-sizing: content-box;
      text-align: center;
      line-height: 50px;
      position: relative;
      font-size: 16px;
      font-weight: 300;
      color: #707070;
      font-weight: bold;
      cursor: pointer;
      &.on,&:hover{
        width: 182px;
        height: 50px;
        background: #F3C912;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #F3C912;
        color: #333333;
        &::after{
          position: absolute;
          left: 50%;
          margin-left: -10px;
          border: 10px solid transparent;
          border-top: 10px solid #F3C912;
          bottom: -20px;
          content: '';
          display: block;
        }
      }
    }
  }
  &::after{
    clear: both;
    display: block;
    content: '';
  }
}
.mt35{
  margin-top: 35px;
}
</style>
