import request from "@/utils/request";

// 产品包
const BASE_PATH = "/front/product/bouquet";
export function getProductBouque(query) {
  return request({
    url: BASE_PATH,
    method: "get",
    params: query,
  });
}

export function getProductBouquById(id) {
  return request({
    url: BASE_PATH + "/" + id,
    method: "get",
  });
}
// 标签列表
export function getProductTags(query) {
  return request({
    url: BASE_PATH + "/tags",
    method: "get",
    params: query,
  });
}
// 产品项目
export function getBouquetItem(bouquetId, query) {
  return request({
    url: BASE_PATH + "/"+bouquetId + "/item",
    method: "get",
    params: query,
  });
}

export function getCourseTree(bouquetId, id) {
  return request({
    url: BASE_PATH + "/"+bouquetId + "/item/"+id,
    method: "get",
  });
}
export function getgetProductCount(query) {
  return request({
    url: BASE_PATH + "/_count",
    method: "get",
    params: query,
  });
}
