<template>
  <div class="EduHome">
    <EduHeader @activeName="activeNameFn" />
    <main class="page-container">
      <h2>我的仪表板</h2>
      <EduHomeTabs :activeName.sync="activeName" />
      <div class="tab-content">
        <component :is="activeName" />
      </div>
    </main>
    <footer>版权所有©华路数字文化 版权所有</footer>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import EduHomeTabs from "./components/EduHomeTabs/index.vue";
import notice from "./components/notice/index.vue";
import myTimeBank from "./components/myTimeBank/index.vue";
import ProgressContent from "./components/ProgressContent/index.vue";
import HomeCards from "./components/HomeCards";
import myCourse from "./components/myCourse";
import myAccount from "./components/myAccount";
import addCourse from "./components/addCourse";
import myStudy from "./components/myStudy";
import purchaseRecord from "./components/purchaseRecord";
import learning from "./components/learning";


export default {
  name: "EduHome",
  data() {
    return {
      activeName: "addCourse", //myCourse
    };
  },
  components: {
    EduHeader,
    EduHomeTabs,
    notice,
    myTimeBank,
    ProgressContent,
    HomeCards,
    myCourse,
    myAccount,
    addCourse,
    myStudy,
    purchaseRecord,
    learning,
  },

  methods: {
    activeNameFn(name){
      this.activeName = name
    }
  },

  mounted() {
    console.log('route', this.$route)
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName 
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  flex: 1;
  padding: 50px 40px 42px;

  h2 {
    font-size: 36px;
    color: $color-text-2;
    font-weight: 400;
  }

  ::v-deep .EduHomeTabs {
    margin-top: 14px;
  }

  .tab-content {
    // width: 1200px;
    border: 1px solid $color-border-0;
  }
}

.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
</style>
