<template>
  <div class="AdvanceItem">
    <div class="title">{{ obj.title }}</div>
    <div class="percent">
      <CirclePercent :percent="obj.percent" v-if="isPercent" />
      <span v-else>
        {{ obj.percent }}
      </span>
    </div>
    <div class="test" v-if="isTest">
      <div class="progress">
        <CirclePercent :percent="obj.progress" v-if="isProgress" />
        <span v-else>
          {{ obj.progress }}
        </span>
      </div>
      <div class="desc">
        <div class="content" v-if="isDesc">
          <div class="line">到期时间：</div>
          <div class="line">{{ obj.desc.endTime }}</div>
          <div class="line">拍摄时间：</div>
          <div class="line">{{ obj.desc.startTime }}</div>
        </div>
        <span v-else>
          {{ obj.desc }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.AdvanceItem {
  border: 1px solid $color-border-3;
  div:last-of-type {
    border: none;
  }
  .title,
  .percent,
  .test {
    width: 160px;
    border-bottom: 1px solid $color-border-3;
  }

  .title {
    height: 50px;
    @include flex-center;
    font-size: 12px;
  }
  .percent {
    height: 86px;
    @include flex-center;
    font-weight: bold;
  }
  .progress {
    height: 92px;
    display: flex;
    justify-content: center;
    padding-top: 21px;
  }
  .desc {
    text-align: center;
    height: 88px;
    font-weight: bold;

    .content {
      padding: 0 16px;
      font-size: 12px;
      font-weight: normal;
    }

    .line {
      text-align: left;
    }
  }
}
</style>

<script>
import CirclePercent from "@/components/CirclePercent/index";
export default {
  name: "AdvanceItem",
  components: {
    CirclePercent,
  },
  props: {
    obj: {
      type: Object,
      default: () => ({
        title: "",
        percent: "完成百分比",
        progress: "",
        desc: "测验分数",
      }),
    },
    isTest: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isPercent() {
      return typeof this.obj.percent === "number";
    },
    isProgress() {
      return typeof this.obj.progress === "number";
    },
    isDesc() {
      return typeof this.obj.desc === "object";
    },
  },
};
</script>
